
/* Install Section Styling */
.install-buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}



/* Settings Section Styling */
.settings-group {
    margin-bottom: 30px;
}

.settings-group h3 {
    margin-bottom: 15px;
}

.settings-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.settings-group button {
    margin-top: 10px;
}



.sidebar {
    width: 250px;
    position: fixed;
    top: 70px;
    /* Assuming the navbar height is 70px */
    left: 0;
    height: calc(100% - 70px);
    /* Subtract navbar height from the sidebar */
    background-color: var(--section-bg-color);
    /* Dynamic variable color */
    padding-top: 20px;
    transition: all 0.3s ease;
    z-index: 1000;
    /* Ensure the sidebar stays above other content */
}

.sidebar-logo h2 {
    text-align: center;
    color: black;
    margin-bottom: 30px;
    font-family: 'Inter', sans-serif;
}

.sidebar-nav {
    list-style-type: none;
    padding: 0;
}

.sidebar-nav li {
    margin: 15px 0;
}

.sidebar-nav a {
    text-decoration: none;
    font-size: 18px;
    color: var(--dark-color);
    /* Using a variable for text color */
    padding: 15px;
    display: block;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;
}

.sidebar-nav a:hover,
.sidebar-nav a.active {
    background-color: var(--primary-color);
    /* Dynamic variable */
    color: white;
}

.sidebar-nav a i {
    margin-right: 10px;
    font-size: 20px;
}

.dashboard-container {
    margin-top: 70px;
    padding: 20px;
    background-color: #f8f9fa;
    min-height: calc(100vh - 70px);
    box-sizing: border-box;
}

/* When Sidebar is active */
.sidebar-active .dashboard-container {
    margin-left: 250px;
}

/* Navbar Styling */
nav.navbar {
    background-color: var(--section-bg-color);
    /* Use section background color */
}

nav .navbar-brand {
    font-weight: 700;
    font-size: 24px;
}

/* Preloader */
.preloader {
    display: none;
    /* Hide preloader by default */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}

.preloader .spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
}

.content-section {
    display: none;
    padding: 25px;
    min-height: 80vh;
}

.active-section {
    display: block;
}

.card-row {
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.class-card {
    margin-left:auto;
    margin-right: auto;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(100% - 40px); /* Ensure the card takes up the full width minus the gap */
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.class-card:hover {
    transform: scale(1.03);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1050;
}
  
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: auto;
    box-sizing: border-box;
    height: fit-content;
}
  
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Blur background when modal is open */
.blur {
    filter: blur(5px);
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
}

form label,
form input,
form button {
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
}

.class-details{
    width: 100%;
    max-width: 1200px;
    max-height:max-content;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}
.class-details{
    max-height:max-content;
}


.question-image {
    width: 700px;
    height: auto;}
/* Content Responsiveness */
.modal-body table {
    width: 100%;
    overflow-x: auto;
}

.modal-body img {
    max-width: 100%;
    height: 500px;
}

.modal-header{
    height: 50px;
}


.contact-form-support{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

/* Custom Alert Class */
.custom-alert {
    background-color: var(--p-color) !important; /* Use the CSS variable for background */
    color: #ffffff !important; /* Set text color to white for contrast */
  }
  
  /* Optional: Change border color to match background */
.custom-alert {
border-color: black !important;
}

.image-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .image-row img {
    max-width: 100px;
    cursor: pointer;
  }
  

@media (max-width: 768px) {

    .sidebar-active .dashboard-container {
        margin-left: 0;
    }

    .modal-content {
        max-width: 95%;
        padding: 15px;
    }

    .dashboard-container {
        margin-left: 0;
        margin-top: 70px;
    }

    .sidebar {
        width: 100%;
        position: relative;
        height: auto;
    }

    body {
        margin-left: 0;
        margin-top: 0;
        /* Reset margin for mobile */
    }

    nav .navbar-brand {
        margin-left: 0;
    }

    nav.navbar {
        margin-left: 0;
    }

    .question-image {
        width: 100%;
        height: auto;
    }
}
@media (max-width: 768px) {
    /* Hide the sidebar logo on mobile */
    .sidebar-logo h2 {
        display: none !important;
    }

    /* Position the sidebar at the bottom */
    .sidebar {
        width: 100% !important;
        position: fixed !important;
        bottom: 0 !important;
        top: auto !important; /* Ensure top positioning is reset */
        left: 0 !important;
        height: 60px !important; /* Adjust the height as needed */
        background-color: var(--section-bg-color) !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 0 !important;
        z-index: 1000 !important;
    }

    /* Adjust the navigation list to be horizontal */
    .sidebar-nav {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-around !important;
        align-items: center !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        list-style: none !important;
    }

    /* Remove margins from list items */
    .sidebar-nav li {
        margin: 0 !important;
        flex: 1 !important; /* Each nav item takes equal space */
    }

    /* Style the links */
    .sidebar-nav a {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        text-decoration: none !important;
        color: var(--dark-color) !important;
        padding: 5px 0 !important;
        font-size: 12px !important;
        transition: background-color 0.3s, color 0.3s !important;
    }

    .sidebar-nav a i {
        font-size: 20px !important;
    }

    /* Adjust active and hover styles */
    .sidebar-nav a:hover,
    .sidebar-nav a.active {
        background-color: var(--primary-color) !important;
        color: white !important;
    }

    /* Adjust the main content to have margin at the bottom */
    .dashboard-container {
        margin-bottom: 60px !important; /* Height of the bottom nav bar */
        margin-top: 70px !important; /* Adjust if you have a top navbar */
    }

    /* Ensure the content sections are displayed properly */
    .content-section {
        padding: 15px !important;
    }
}

