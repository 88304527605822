/* src/pages/Student/StudentDashboard.css */

/* Center content for mobile */
.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: center;
    margin-top: var(--nav-height);
  }
  
  @media (max-width: 768px) {
    .main-content {
      min-height: 100vh;
    }
  }
  
  .class-card {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
  }
  
  #class-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
  }
  
  .session-details {
    text-align: center;
    padding: 20px;
    margin-top: 4%;
    color: var(--dark-color);
  }
  
  .responsive-image {
    max-width: 50%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .responsive-image {
      max-width: 100% !important;
    }
  }
  
  .session-details p {
    margin-top: 15px;
    font-size: 1.2em;
    color: var(--p-color);
  }
  
  .answer-btn {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: var(--grey-color);
    color: var(--dark-color);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .answer-btn:hover:not([disabled]) {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .answer-btn.selected {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 2px solid var(--primary-color);
  }
  
  .answer-btn:disabled {
    background-color: var(--grey-color);
    color: var(--p-color);
    cursor: not-allowed;
  }
  
  .answer-btn.selected:disabled {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 2px solid var(--primary-color);
  }
  
  .poll-closed-msg {
    color: var(--primary-color);
    font-weight: bold;
    margin-top: 20px;
  }
  
  .correct-answer h4 {
    color: var(--primary-color);
    font-weight: bold;
  }
  
  .multiple-choice,
  .yes-no,
  .short-input {
    margin-top: 20px;
  }
  

  .class-card.active-session {
    background-color: #e0f7e9; /* Light green background */
    border: 2px solid #34a853; /* Green border */
  }
  
  .session-active {
    color: #34a853; /* Green text */
    font-weight: bold;
  }
  .correct-answer.correct {
    color: green;
  }
  
  .correct-answer.incorrect {
    color: #d00000;
  }
    